/* eslint-disable camelcase */
/* eslint-disable indent */
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'
import idx from 'idx'
import { getCookie } from '../../../utils/cookie'
import { trackEvent } from '../../../utils/eventTrack'
import parseFullName from '../../../utils/parseFullName'
import { getFeatureGates } from '../../../utils/getGatesClient'
import { WCAG_SUCCESS_CRITERIA } from '../../../utils/scan/wcagSuccessCriteria'
import generateConsentFormFieldsTranslations from '../../../data/generateConsentFormFieldsTranslations'

const hsCookie = getCookie('hubspotutk')
const windowGlobal = typeof window !== 'undefined'
const params = new URLSearchParams(windowGlobal && window.location.search)

export const trackForm = (formData, formId) => {
  const mappedData = mapValues(keyBy(formData, 'name'), 'value')

  if (windowGlobal) {
    trackEvent(`Hubspot Form Submitted`, {
      'Job Title': mappedData.jobtitle || '',
      Website: mappedData.website || '',
      'Company Name': mappedData.company || '',
      'Hubspot Form Name': mappedData.form_name || '',
      'Hubspot Form ID': formId,
      'Agency or Freelancer': mappedData.agency_or_freelancer__c || '',
      Email: mappedData.email || '',
    })

    if (mappedData.form_name === 'Free Consultation Form' && window.sessionStorage.getItem('gate') === 'false') {
      trackEvent('Demo Request After Free Scan')
    }
  }
}

const persistedUtmParams = windowGlobal && JSON.parse(window.localStorage.getItem('utm_params'))
const utmParams = {
  utmCampaign: params.get('utm_campaign') || idx(persistedUtmParams, _ => _.utm_campaign) || '',
  utmContent: params.get('utm_content') || idx(persistedUtmParams, _ => _.utm_content) || '',
  utmMedium: params.get('utm_medium') || idx(persistedUtmParams, _ => _.utm_medium) || '',
  utmSource: params.get('utm_source') || idx(persistedUtmParams, _ => _.utm_source) || '',
  utmTerm: params.get('utm_term') || idx(persistedUtmParams, _ => _.utm_term) || '',
  utmPlacement: params.get('utm_placement') || idx(persistedUtmParams, _ => _.utm_placement) || '',
}

export const onboardingHsFormCollect = async ({
  formData,
  portalAccountId,
  portalUserId,
  showConsentForm,
  formID,
  userLang,
}) => {
  const languageData = generateConsentFormFieldsTranslations({ userLang })
  const {
    email,
    fullName,
    url,
    freelancerAgency,
    legalConsentSubscription,
    legalConsentProcessing,
    company,
    form_name,
    intlPhoneNum,
  } = formData

  const { firstName, lastName } = parseFullName(fullName)
  const persistedGclidParam = windowGlobal && JSON.parse(window.localStorage.getItem('gclid_param'))
  const featureGates = JSON.stringify(getFeatureGates())

  const context = {
    hutk: hsCookie,
    pageUri: (window && window.location.href) || '#get-started-free',
    pageName: document.title || '',
  }

  let legalOptions = {}

  if (showConsentForm && legalConsentSubscription && legalConsentProcessing) {
    legalOptions = {
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: languageData?.checkboxLabelTwo,
          communications: [
            {
              value: true,
              subscriptionTypeId: process.env.GATSBY_ACTIVE_ENV === 'production' ? 84473905 : 332154496, // these are the Subscription Types in hubspot
              text: languageData?.checkboxLabelOne,
            },
          ],
        },
      },
    }
  }

  const userInfo = await fetch(`/user/fetch-user-info`, {
    headers: {
      'Content-type': 'application/json',
    },
    method: 'GET',
  })

  if (userInfo.ok) {
    const user = await userInfo.json()
    context.ipAddress = user.ip
  }

  try {
    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${formID}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submittedAt: `${Date.now()}`,
          fields: [
            {
              name: 'email',
              value: email || '',
            },
            {
              name: 'firstname',
              value: firstName || '',
            },
            {
              name: 'lastname',
              value: lastName || '',
            },
            {
              name: 'phone',
              value: intlPhoneNum || '',
            },
            {
              name: 'website',
              value: url || '',
            },
            {
              name: 'agency_or_freelancer__c',
              value: freelancerAgency || '',
            },
            {
              name: 'company',
              value: company || '',
            },
            {
              name: 'variant',
              value: featureGates,
            },
            {
              name: '0-2/portal_account_id',
              value: portalAccountId || '',
            },
            {
              name: 'user_id__c',
              value: portalUserId || '',
            },
            {
              name: 'utm_campaign',
              value: utmParams.utmCampaign,
            },
            {
              name: 'utm_content',
              value: utmParams.utmContent,
            },
            {
              name: 'utm_medium',
              value: utmParams.utmMedium,
            },
            {
              name: 'utm_source',
              value: utmParams.utmSource,
            },
            {
              name: 'utm_term',
              value: utmParams.utmTerm,
            },
            {
              name: 'gclid__c',
              value: persistedGclidParam || '',
            },
            {
              name: 'mutiny_experience_name',
              value: localStorage.getItem('mutiny_experience_name') || '',
            },
            {
              name: 'mutiny_segment_name',
              value: localStorage.getItem('mutiny_segment_name') || '',
            },
            {
              name: 'mutiny_variation_name',
              value: localStorage.getItem('mutiny_variation_name') || '',
            },
          ],
          context,
          ...(showConsentForm ? legalOptions : null),
        }),
      }
    )

    if (response.ok) {
      trackEvent(`Hubspot Form Submitted`, {
        'Job Title': '',
        Website: url || '',
        'Hubspot Form Name': form_name || '',
        'Hubspot Form ID': formID,
        Email: email || '',
        'Agency or Freelancer': freelancerAgency || '',
      })
    }
    if (!response.ok) {
      const resBody = await response.json()
      trackEvent(`API Exception`, {
        Label: 'Hubspot form submit onboarding error',
        'Full Exception': resBody,
      })
    }
  } catch (err) {
    trackEvent(`API Exception`, {
      Label: 'Hubspot form submit onboarding catch error',
      'Full Exception': err,
    })
  }
}

export const scannerGateHsFormCollect = async (formData, scoreData, url, showConsentForm, formID, userLang) => {
  const languageData = generateConsentFormFieldsTranslations({ userLang })
  const beneficiaryData = {}

  if (scoreData && scoreData.testsWithFailures) {
    scoreData.testsWithFailures.forEach(item => {
      if (item.ruleMetadata.primaryBeneficiary === 'Visual') beneficiaryData.visual = item.fail
      if (item.ruleMetadata.primaryBeneficiary === 'Cognitive') beneficiaryData.cognitive = item.fail
      if (item.ruleMetadata.primaryBeneficiary === 'Motor') beneficiaryData.motor = item.fail
      if (item.ruleMetadata.primaryBeneficiary === 'Deafblind') beneficiaryData.deafblind = item.fail
      if (item.ruleMetadata.primaryBeneficiary === 'Auditory') beneficiaryData.auditory = item.fail
      if (item.ruleMetadata.primaryBeneficiary === 'Seizure') beneficiaryData.seizure = item.fail
    })
  }
  const {
    email,
    fullName,
    form_name,
    education_role,
    freelancerAgency,
    legalConsentSubscription,
    legalConsentProcessing,
    scannerScoreUrl,
    baseScannerUrl,
  } = formData

  const { firstName, lastName } = parseFullName(fullName)
  const persistedGclidParam = windowGlobal && JSON.parse(window.localStorage.getItem('gclid_param'))
  const featureGates = JSON.stringify(getFeatureGates())

  try {
    const context = {
      hutk: hsCookie,
      pageUri: (window && window.location.href) || '',
      pageName: document.title || '',
    }

    let legalOptions = {}

    if (showConsentForm && legalConsentSubscription && legalConsentProcessing) {
      legalOptions = {
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: languageData?.checkboxLabelTwo,
            communications: [
              {
                value: true,
                subscriptionTypeId: process.env.GATSBY_ACTIVE_ENV === 'production' ? 84473905 : 332154496, // these are the Subscription Types in hubspot
                text: languageData?.checkboxLabelOne,
              },
            ],
          },
        },
      }
    }

    const userInfo = await fetch(`/user/fetch-user-info`, {
      headers: {
        'Content-type': 'application/json',
      },
      method: 'GET',
    })

    if (userInfo.ok) {
      const user = await userInfo.json()
      context.ipAddress = user.ip
    }

    const response = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${formID}`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          submittedAt: `${Date.now()}`,
          fields: [
            {
              name: 'email',
              value: email,
            },
            {
              name: 'firstname',
              value: firstName,
            },
            {
              name: 'lastname',
              value: lastName,
            },
            {
              name: 'website',
              value: url,
            },
            {
              name: 'education_role',
              value: education_role || '',
            },
            {
              name: 'agency_or_freelancer__c',
              value: freelancerAgency,
            },
            {
              name: 'scanner_score__c',
              value: scoreData?.pageScore || '',
            },
            {
              name: 'scanner_total_number_of_errors__c',
              value: scoreData?.totalErrors || '',
            },
            {
              name: 'scanner_visual_error_count',
              value: beneficiaryData?.visual || '',
            },
            {
              name: 'scanner_auditory_error_count',
              value: beneficiaryData?.auditory || '',
            },
            {
              name: 'scanner_cognitive_error_count',
              value: beneficiaryData?.cognitive || '',
            },
            {
              name: 'scanner_motor_error_count',
              value: beneficiaryData?.motor || '',
            },
            {
              name: 'scanner_score_url__c',
              value: scannerScoreUrl,
            },
            {
              name: 'scanner_issue_code_1',
              value: scoreData.testsWithFailures?.[0]?.code || '',
            },
            {
              name: 'top_issue_1_description',
              value: scoreData.testsWithFailures?.[0]?.ruleMetadata?.description || '',
            },
            {
              name: 'top_issue_1_primary_beneficiary',
              value: scoreData.testsWithFailures?.[0]?.ruleMetadata?.primaryBeneficiary || '',
            },
            {
              name: 'top_issue_1_wcag_criterion',
              value: scoreData.testsWithFailures?.[0]?.ruleMetadata?.standards?.[0]
                ? `WCAG ${
                    WCAG_SUCCESS_CRITERIA[scoreData.testsWithFailures?.[0]?.ruleMetadata?.standards?.[0]?.criteria]
                      ?.level
                  } Success Criterion ${scoreData.testsWithFailures?.[0]?.ruleMetadata?.standards?.[0]?.criteria}`
                : '',
            },
            {
              name: 'top_issue_1_wcag_criterion_resource',
              value:
                WCAG_SUCCESS_CRITERIA[scoreData.testsWithFailures?.[0]?.ruleMetadata?.standards?.[0]?.criteria]
                  ?.resource || '',
            },
            {
              name: 'top_issue_1_user_impact_severity',
              value: scoreData.testsWithFailures?.[0]?.ruleMetadata?.risk || '',
            },
            {
              name: 'top_issue_1_element_count',
              value: scoreData.testsWithFailures?.[0]?.fail || '',
            },
            {
              name: 'scanner_issue_code_2',
              value: scoreData.testsWithFailures?.[1]?.code || '',
            },
            {
              name: 'top_issue_2_description',
              value: scoreData.testsWithFailures?.[1]?.ruleMetadata?.description || '',
            },
            {
              name: 'top_issue_2_primary_beneficiary',
              value: scoreData.testsWithFailures?.[1]?.ruleMetadata?.primaryBeneficiary || '',
            },
            {
              name: 'top_issue_2_wcag_criterion',
              value: scoreData.testsWithFailures?.[1]?.ruleMetadata?.standards?.[0]
                ? `WCAG ${
                    WCAG_SUCCESS_CRITERIA[scoreData.testsWithFailures?.[1]?.ruleMetadata?.standards?.[0]?.criteria]
                      ?.level
                  } Success Criterion ${scoreData.testsWithFailures?.[1]?.ruleMetadata?.standards?.[0]?.criteria}`
                : '',
            },
            {
              name: 'top_issue_2_wcag_criterion_resource',
              value:
                WCAG_SUCCESS_CRITERIA[scoreData.testsWithFailures?.[1]?.ruleMetadata?.standards?.[0]?.criteria]
                  ?.resource || '',
            },
            {
              name: 'top_issue_2_user_impact_severity',
              value: scoreData.testsWithFailures?.[1]?.ruleMetadata?.risk || '',
            },
            {
              name: 'top_issue_2_element_count',
              value: scoreData.testsWithFailures?.[1]?.fail || '',
            },
            {
              name: 'scanner_issue_code_3',
              value: scoreData.testsWithFailures?.[2]?.code || '',
            },
            {
              name: 'top_issue_3_description',
              value: scoreData.testsWithFailures?.[2]?.ruleMetadata?.description || '',
            },
            {
              name: 'top_issue_3_primary_beneficiary',
              value: scoreData.testsWithFailures?.[2]?.ruleMetadata?.primaryBeneficiary || '',
            },
            {
              name: 'top_issue_3_wcag_criterion',
              value: scoreData.testsWithFailures?.[2]?.ruleMetadata?.standards?.[0]
                ? `WCAG ${
                    WCAG_SUCCESS_CRITERIA[scoreData.testsWithFailures?.[2]?.ruleMetadata?.standards?.[0]?.criteria]
                      ?.level
                  } Success Criterion ${scoreData.testsWithFailures?.[2]?.ruleMetadata?.standards?.[0]?.criteria}`
                : '',
            },
            {
              name: 'top_issue_3_wcag_criterion_resource',
              value:
                WCAG_SUCCESS_CRITERIA[scoreData.testsWithFailures?.[2]?.ruleMetadata?.standards?.[0]?.criteria]
                  ?.resource || '',
            },
            {
              name: 'top_issue_3_user_impact_severity',
              value: scoreData.testsWithFailures?.[2]?.ruleMetadata?.risk || '',
            },
            {
              name: 'top_issue_3_element_count',
              value: scoreData.testsWithFailures?.[2]?.fail || '',
            },
            {
              name: 'variant',
              value: featureGates,
            },
            {
              name: 'form_name',
              value: form_name,
            },
            {
              name: 'utm_campaign',
              value: utmParams.utmCampaign,
            },
            {
              name: 'utm_content',
              value: utmParams.utmContent,
            },
            {
              name: 'utm_medium',
              value: utmParams.utmMedium,
            },
            {
              name: 'utm_source',
              value: utmParams.utmSource,
            },
            {
              name: 'utm_term',
              value: utmParams.utmTerm,
            },
            {
              name: 'gclid__c',
              value: persistedGclidParam || '',
            },
            {
              name: 'mutiny_experience_name',
              value: localStorage.getItem('mutiny_experience_name') || '',
            },
            {
              name: 'mutiny_segment_name',
              value: localStorage.getItem('mutiny_segment_name') || '',
            },
            {
              name: 'mutiny_variation_name',
              value: localStorage.getItem('mutiny_variation_name') || '',
            },
          ],
          context,
          ...(showConsentForm ? legalOptions : null),
        }),
      }
    )
    if (response.ok) {
      const data = {
        'Job Title': '',
        Website: url || '',
        'Hubspot Form Name': form_name || '',
        'Hubspot Form ID': formID,
        Email: email || '',
        'Agency or Freelancer': freelancerAgency || '',
      }

      if (utmParams.utmCampaign === 'Generic-Wcag-Testing-Free-Site-Scan-97f') {
        data['Business Email Paid Test'] = 'true'
      }

      trackEvent(`Hubspot Form Submitted`, data)

      window.history.pushState({}, '', baseScannerUrl)

      // Don't show the form again
      window.sessionStorage.setItem('gate', false)
    }
    if (!response.ok) {
      const resBody = await response.json()
      trackEvent(`API Exception`, {
        Label: 'Hubspot form submit scanner gate form error',
        'Full Exception': resBody,
      })
    }
  } catch (err) {
    trackEvent(`API Exception`, {
      Label: 'Hubspot form submit scanner gate form catch error',
      'Full Exception': err,
    })
  }
}
